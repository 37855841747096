import { HStack, VStack, Text } from '@chakra-ui/react';
import { useBalance, useChainId } from '@thirdweb-dev/react';
import React, { FC, useCallback, useMemo } from 'react';

import { DebouncedInput } from '@app/components/DebouncedInput';
import { TokenSelector } from '@app/components/Swap/components/TokenSelector';
import { Currency, Percent } from '@cryptoalgebra/kim-sdk';
import { SwapFieldType } from '@app/types/swap-field';
import { Address } from '@thirdweb-dev/sdk';
import { formatCurrency } from '@app/helpers/formatCurrency';
import {
  getCurrencyFromKimToken,
  getKimTokenFromCurrency
} from '@app/helpers/currency';
import { formatUSD } from '@app/helpers/formatUSD';

interface Props {
  label: string;
  handleTokenSelection: (currency: Currency) => void;
  handleValueChange: (value: string) => void;
  handleMaxValue?: () => void;
  value: string;
  currency: Currency | null | undefined;
  otherCurrency: Currency | null | undefined;
  fiatValue: number | undefined;
  priceImpact: Percent | undefined;
  showMaxButton?: boolean;
  field: SwapFieldType;
  filterByPools?: boolean;
}

export const TokenInputV3: FC<Props> = ({
  handleValueChange,
  value,
  currency,
  fiatValue,
  label,
  handleTokenSelection,
  filterByPools
}) => {
  const { data: balance, isLoading } = useBalance(
    currency?.isNative ? undefined : (currency?.wrapped.address as Address)
  );
  const chainId = useChainId();

  const balanceString = useMemo(() => {
    if (isLoading || !balance) return 'Loading...';

    return formatCurrency.format(Number(balance.displayValue));
  }, [balance, isLoading]);

  const handleInput = useCallback(
    (value: string) => {
      if (value === '.') value = '0.';

      // Replace commas with dots
      value = value.replace(/,/g, '.');

      // Allow only numbers and a single dot
      if (/^\d*\.?\d*$/.test(value)) {
        handleValueChange(value);
      }
    },
    [handleValueChange]
  );

  return (
    <VStack
      w="100%"
      bg="neutral.1000"
      transition="background 0.2s ease"
      px="16px"
      py="12px"
      borderRadius="12px"
      overflow="hidden"
    >
      <Text
        textAlign="left"
        w="100%"
        color="neutral.300"
        fontSize="12px"
        lineHeight="18px"
        letterSpacing="-0.18px"
        mb="8px"
      >
        {label}
      </Text>
      <HStack w="100%" mb="4px">
        <TokenSelector
          onSelect={val => {
            if (!chainId) {
              return;
            }

            const _currency = getCurrencyFromKimToken(val, chainId);

            if (!_currency) {
              return;
            }

            handleTokenSelection(_currency);
          }}
          disabled={false}
          selected={getKimTokenFromCurrency(currency)}
          filterByPools={filterByPools}
          isV3
        />
        <DebouncedInput
          amount={value}
          disabled={false}
          onChange={handleInput}
          type="text"
          // readOnlyValue={readOnlyValue}
        />
      </HStack>
      <HStack w="100%" justify="space-between">
        <HStack justify="flex-start">
          <Text
            color="neutral.500"
            fontSize="12px"
            fontWeight="400"
            lineHeight="18px"
            letterSpacing="-0.18px"
          >
            Balance:
          </Text>
          <Text
            color="neutral.400"
            fontSize="12px"
            fontWeight="400"
            lineHeight="18px"
            letterSpacing="-0.18px"
          >
            {currency && balanceString ? balanceString : 'n/a'}
          </Text>
        </HStack>
        <Text
          color="neutral.400"
          fontSize="12px"
          fontWeight="400"
          lineHeight="18px"
          letterSpacing="-0.18px"
        >
          {(fiatValue && formatUSD.format(fiatValue)) ?? '$0'}
        </Text>
      </HStack>
    </VStack>
  );
};
