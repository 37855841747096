import {
  Currency,
  CurrencyAmount,
  Route,
  Trade,
  TradeType,
  encodeRouteToPath
} from '@cryptoalgebra/kim-sdk';
import { useMemo } from 'react';
import { useAllRoutes } from './useAllRoutes';
import { TradeState, TradeStateType } from 'src/types/trade-state';
import { algebraQuoterV2ABI } from '../../../abis/v3';
import { useAsync } from 'react-use';
import { useContract } from '@thirdweb-dev/react-core';
import { useConfig } from '@app/config';

// const DEFAULT_GAS_QUOTE = 2_000_000

/**
 * Returns the best v3 trade for a desired exact input swap
 * @param amountIn the amount to swap in
 * @param currencyOut the desired output currency
 */
export function useBestTradeExactIn(
  amountIn?: CurrencyAmount<Currency>,
  currencyOut?: Currency
): {
  state: TradeStateType;
  trade: Trade<Currency, Currency, TradeType.EXACT_INPUT> | null;
  fee?: bigint[] | null;
  priceAfterSwap?: bigint[] | null;
} {
  const { routes, loading: routesLoading } = useAllRoutes(
    amountIn?.currency,
    currencyOut
  );

  const quoteExactInInputs = useMemo(() => {
    return routes.map(route => [
      encodeRouteToPath(route, false),
      amountIn ? `0x${amountIn.quotient.toString(16)}` : undefined
    ]);
  }, [amountIn, routes]);

  const config = useConfig();

  const { contract: quoterContract } = useContract(
    config?.ALGEBRA_ADDRESSES.ALGEBRA_QUOTER_V2,
    algebraQuoterV2ABI
  );

  const { value: quotesResults, loading: isQuotesLoading } =
    useAsync(async () => {
      if (!quoterContract) {
        return;
      }

      try {
        const results = await Promise.allSettled(
          quoteExactInInputs.map(async quote => {
            const res = await quoterContract.call('quoteExactInput', quote);

            return { error: undefined, result: res, status: 'success' };
          })
        );

        return results;
      } catch (e) {
        return [];
      }
    }, [quoterContract, quoteExactInInputs]);

  const trade = useMemo(() => {
    if (!amountIn || !currencyOut) {
      return {
        state: TradeState.INVALID,
        trade: null
      };
    }

    if (routesLoading || isQuotesLoading) {
      return {
        state: TradeState.LOADING,
        trade: null
      };
    }

    const { bestRoute, amountOut, fee, priceAfterSwap } = (
      quotesResults || []
    ).reduce(
      (
        currentBest: {
          bestRoute: Route<Currency, Currency> | null;
          amountOut: any | null;
          fee: bigint[] | null;
          priceAfterSwap: bigint[] | null;
        },
        item: any,
        i
      ) => {
        if (item.status === 'rejected') {
          return currentBest;
        }

        const { result } = item.value;

        if (!result) return currentBest;

        if (currentBest.amountOut === null) {
          return {
            bestRoute: routes[i],
            amountOut: result[0],
            fee: result[5],
            priceAfterSwap: result[2]
          };
        } else if (currentBest.amountOut.lt(result[0])) {
          return {
            bestRoute: routes[i],
            amountOut: result[0],
            fee: result[5],
            priceAfterSwap: result[2]
          };
        }

        return currentBest;
      },
      {
        bestRoute: null,
        amountOut: null,
        fee: null,
        priceAfterSwap: null
      }
    );

    if (!bestRoute || !amountOut) {
      return {
        state: TradeState.NO_ROUTE_FOUND,
        trade: null,
        fee: null,
        priceAfterSwap: null
      };
    }

    return {
      state: TradeState.VALID,
      fee,
      trade: Trade.createUncheckedTrade({
        route: bestRoute,
        tradeType: TradeType.EXACT_INPUT,
        inputAmount: amountIn,
        outputAmount: CurrencyAmount.fromRawAmount(
          currencyOut,
          amountOut.toString()
        )
      }),
      priceAfterSwap
    };
  }, [
    amountIn,
    currencyOut,
    quotesResults,
    routes,
    routesLoading,
    isQuotesLoading
  ]);

  return trade;
}

/**
 * Returns the best v3 trade for a desired exact output swap
 * @param currencyIn the desired input currency
 * @param amountOut the amount to swap out
 */
export function useBestTradeExactOut(
  currencyIn?: Currency,
  amountOut?: CurrencyAmount<Currency>
): {
  state: TradeStateType;
  trade: Trade<Currency, Currency, TradeType.EXACT_OUTPUT> | null;
  fee?: bigint[] | null;
  priceAfterSwap?: bigint[] | null;
} {
  const { routes, loading: routesLoading } = useAllRoutes(
    currencyIn,
    amountOut?.currency
  );
  const config = useConfig();

  const quoteExactOutInputs = useMemo(() => {
    return routes.map(route => [
      encodeRouteToPath(route, true),
      amountOut ? `0x${amountOut.quotient.toString(16)}` : undefined
    ]);
  }, [amountOut, routes]);

  const { contract: quoterContract } = useContract(
    config?.ALGEBRA_ADDRESSES.ALGEBRA_QUOTER_V2,
    algebraQuoterV2ABI
  );

  const {
    value: quotesResults,
    loading: isQuotesLoading,
    error: isQuotesErrored
  } = useAsync(async () => {
    if (!quoterContract) {
      return;
    }

    const result = await Promise.all(
      quoteExactOutInputs.map(async quote => {
        const res = await quoterContract.call('quoteExactOutput', quote);

        return { error: undefined, result: res, status: 'success' };
      })
    );

    return result;
  }, [quoterContract, quoteExactOutInputs]);

  const trade = useMemo(() => {
    if (!amountOut || !currencyIn || isQuotesErrored) {
      return {
        state: TradeState.INVALID,
        trade: null
      };
    }

    if (routesLoading || isQuotesLoading) {
      return {
        state: TradeState.LOADING,
        trade: null
      };
    }

    const { bestRoute, amountIn, fee, priceAfterSwap } = (
      quotesResults || []
    ).reduce(
      (
        currentBest: {
          bestRoute: Route<Currency, Currency> | null;
          amountIn: any | null;
          fee: bigint[] | null;
          priceAfterSwap: bigint[] | null;
        },
        { result }: any,
        i
      ) => {
        if (!result) return currentBest;

        if (currentBest.amountIn === null) {
          return {
            bestRoute: routes[i],
            amountIn: result[1],
            fee: result[5],
            priceAfterSwap: result[2]
          };
        } else if (currentBest.amountIn.lt(result[0])) {
          return {
            bestRoute: routes[i],
            amountIn: result[1],
            fee: result[5],
            priceAfterSwap: result[2]
          };
        }

        return currentBest;
      },
      {
        bestRoute: null,
        amountIn: null,
        fee: null,
        priceAfterSwap: null
      }
    );

    if (!bestRoute || !amountIn) {
      return {
        state: TradeState.NO_ROUTE_FOUND,
        trade: null,
        fee: null,
        priceAfterSwap
      };
    }

    return {
      state: TradeState.VALID,
      fee,
      trade: Trade.createUncheckedTrade({
        route: bestRoute,
        tradeType: TradeType.EXACT_OUTPUT,
        inputAmount: CurrencyAmount.fromRawAmount(
          currencyIn,
          amountIn.toString()
        ),
        outputAmount: amountOut
      }),
      priceAfterSwap
    };
  }, [
    amountOut,
    currencyIn,
    quotesResults,
    routes,
    routesLoading,
    isQuotesLoading,
    isQuotesErrored
  ]);

  return trade;
}
